import Swiper from "swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}

swiper(".hero__container", {
  slidesPerView: 1,
  navigation: {
    nextEl: ".hero__slider-next",
    prevEl: ".hero__slider-prev"
  }
});

/* -------------- */
/* -------------- */
swiper(".logos__swiper", {
  slidesPerView: 2,
  spaceBetween: 30,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    992: {
      slidesPerView: 5
    },

    768: {
      slidesPerView: 3
    }
  }
});

/* -------------- */
/* -------------- */
swiper(".testimonials__swiper", {
  navigation: {
    nextEl: ".swiper-button-next-1",
    prevEl: ".swiper-button-prev-1"
  }
});
/* -------------- */
/* -------------- */
swiper(".our-partners__swiper", {
  slidesPerView: 1.5,
  spaceBetween: 30,
  breakpoints: {
    1200: {
      slidesPerView: 4.5
    },

    992: {
      slidesPerView: 3.5
    },

    768: {
      slidesPerView: 2.5
    },
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }

});
/* -------------- */
/* -------------- */
swiper(".our-customers__swiper", {
  slidesPerView: 2,
  slidesPerColumn: 1,
  breakpoints: {

    992: {
      slidesPerView: 4,
      slidesPerColumn: 2,
      spaceBetween: 30
    },

    768: {
      slidesPerView: 4,
      slidesPerColumn: 1,
    },
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }

});
/* -------------- */
/* -------------- */
swiper(".lading__swiper", {
  slidesPerView: 1.5,
  spaceBetween: 30,
  breakpoints: {
    1200: {
      slidesPerView: 3
    },

    992: {
      slidesPerView: 2
    },

    // 768: {
    //   slidesPerView: 2.5
    // },
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }

});
