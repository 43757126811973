import $ from "jquery";

var siteContentSection = $('.main');
var siteHeader         = $('.header');
var siteNav            = $('.nav');
var siteNavToggler     = $('.nav-toggler');

$(".nav-toggler").on("click", function(e) {
  e.stopPropagation();

  siteNavToggler.toggleClass("nav-toggler--open");
  siteContentSection.toggleClass("main--nav-toggled");
  siteNav.toggleClass("nav--open");
  siteHeader.toggleClass("header--nav-open main--nav-toggled");
});


$(document).click(e => {
  if(!$(e.target).is('.nav') && !$(e.target).is('#mobileSearch') && $('.nav:not(.nav--open)').length == 0){
    siteNavToggler.removeClass("nav-toggler--open");
    siteContentSection.removeClass("main--nav-toggled");
    siteNav.removeClass("nav--open");
    siteHeader.removeClass("header--nav-open main--nav-toggled");
  }
})

$(function() {
  const $navbar = $(".header");

  let lastScrollTop = 0,
      lastDirection;

  $(window).scroll(function(event) {
    const st = $(this).scrollTop();

    if (st <= 0) {
      $navbar.removeClass("header--up");
    } else {

      /** scrolled down */
      if (st > lastScrollTop) {
        lastDirection = 'up';
        $navbar
          .removeClass("header--up")
          .addClass("header--down");
          
      /** scrolled up */
      } else {
        var scrollDifference = st - lastScrollTop;

        /** Ignore small scroll up movements because anchor links cause the page to scroll up when they finish scrolling causing the navbar to show. */
        if (scrollDifference <= -5 && scrollDifference >= -20) {
          lastScrollTop = st;
          return false;
        }

        if (lastDirection !== 'down') {
          $navbar
            .removeClass("header--down")
            .addClass("header--up");
        }

        lastDirection = 'down';
      }
    }

    lastScrollTop = st;
  });
});
