import $ from "jquery";

$("#grid").on("click", ".post-card__share-btn", function (){
  $(this).parents('.post-card__share').find('.share').toggleClass('share--open')
});

// $(".post-card__share-btn").on("click", function() {
// });


/* SEARCH ICON */
$('.search-icon').on('click', function(){
  $(this).toggleClass('search-icon--active')
  $('.search-form-header').toggleClass('search-form-header--active')
});


/* OFFSETTING SLIDER TO RIGHT */
$(window).on("resize", ppaFullWidth);
function ppaFullWidth() {
  var $elements = $('[data-ppa-full-width="true"]');
  $.each($elements, function(key, item) {
    var $el = $(this);
    var $container = $el.closest(".container");
    var margin = parseInt($container.css("margin-left"), 10);
    var padding = parseInt($container.css("padding-left"), 10);
    var offset = margin + padding;

    $el.css({
      position: "relative",
      left: "0",
      width: $(window).width(),
      "padding-right": offset + "px"
    });
  });
}
ppaFullWidth();

/* Make team circle clickable */
$('.teams__wrap').click(function(e) {
  if(e.target.nodeName != 'a' && e.target.nodeName != 'button'){
    window.location.href = $(this).find('a.teams__wrap-link').attr('href');
  }
});