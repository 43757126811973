import $ from "jquery";

var getDateString = function(date, format) {
  var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    getPaddedComp = function(comp) {
      return parseInt(comp) < 10 ? "0" + comp : comp;
    },
    formattedDate = format,
    o = {
      "y+": date.getFullYear(), // year
      "M+": months[date.getMonth()], //month
      "d+": getPaddedComp(date.getDate()), //day
      "h+": getPaddedComp(
        date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
      ), //hour
      "m+": getPaddedComp(date.getMinutes()), //minute
      "s+": getPaddedComp(date.getSeconds()), //second
      "S+": getPaddedComp(date.getMilliseconds()), //millisecond,
      "t+": date.getHours() >= 12 ? "pm" : "am"
    };

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k]);
    }
  }
  return formattedDate;
};

if ($("#grid").length) {
  /* ============================= */
  /* ============================= */
  /* ============================= */
  /* ============================= */
  /* ============================= */

  // LOAD MORE event

  let page = 2;

  $("#loadMoreNewsPost").click(function() {
    const button = $(this);

    $.ajax({
      url: "/wp-json/wp/v2/news_and_events?_embed&per_page=10&page=" + page,
      type: "GET",

      beforeSend: function() {
        button.addClass("spinning");
      },

      success: function(data) {
        if (data) {
          $("#grid").append(postsHTML(data));
          if (button.data("max-pages") == page) {
            button.remove();
          } else {
            page++;
          }
        } else {
          button.remove();
        }
        button.removeClass("spinning");
      }
    });
  });

  function postsHTML(posts) {
    let postsHTML = "";

    posts.forEach(function(post) {
      const title = post.title.rendered,
        link = post.link,
        excerpt = post.excerpt.rendered,
        tag = post.tags[0],
        image = post._embedded["wp:featuredmedia"][0].source_url;

      //   console.log(post.tags[0]);
      // content = strip(post.content.rendered).substring(0, 229, "...");

      postsHTML += `<div class="item eventPost__item" data-aos="fade-up">
      <div class="eventPost__content">
      <a href="${link}" class="eventPost__link"></a>

      <div class="eventPost__imgWrap eventPost__imgWrap--${tag}" style="background-image: url(${image});"></div>
      <div class="eventPost__text">
      <small class="date-text">
      ${getDateString(new Date(post.date), "M d, y h:mt")}
      </small>
      <div class="eventPost__title">
      <h2>${title}</h2>
      </div>
      <div class="eventPost__desc">
      ${excerpt}
      </div>
      </div>

        <div class="post-card__share">
            <div class="post-card__share-btn-wrap">
                <button onlick="shareBtn()" class="post-card__share-btn post-card__btn"><i class="fa fa-share-alt"></i></button>
                <a href="${link}" class="post-card__permalink-btn post-card__btn"><svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M36 8C38.2091 8 40 6.20914 40 4C40 1.79086 38.2091 0 36 0C33.7909 0 32 1.79086 32 4C32 6.20914 33.7909 8 36 8Z" fill="#009ACE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 8C22.2091 8 24 6.20914 24 4C24 1.79086 22.2091 0 20 0C17.7909 0 16 1.79086 16 4C16 6.20914 17.7909 8 20 8Z" fill="#009ACE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z" fill="#009ACE"/>
</svg>
</a>
            </div>
            
            <div class="share">
                <a class="share__link share__facebook" href="https://www.facebook.com/share.php?u=${link}&title=${title}" rel="noopener norefferer" target="blank"><i class="fa fa-facebook"></i></a>
                <a class="share__link share__twitter" href="https://twitter.com/intent/tweet?status=${title}+${link}" rel="noopener noreferrer" target="blank"><i class="fa fa-twitter"></i></a>
                <a class="share__link share__googleplus" href="https://plus.google.com/share?url=${link}" rel="noopener norefferer" target="blank"><i class="fa fa-google-plus"></i></a>
                <a class="share__link share__linkedin" href="https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${title}&source={{source}}" rel="noopener norefferer" target="blank"><i class="fa fa-linkedin"></i></a>            
                <a class="share__link share__pinterest" href="https://pinterest.com/pin/create/bookmarklet/?media=${image}&url=${link}&is_video=false&description=${title}" rel="noopener norefferer" target="blank"><i class="fa fa-pinterest-p"></i></a>
            </div>
        </div>
        
      </div>
      </div>`;
    });

    return postsHTML;
  }
}
