import $ from 'jquery';
import 'bootstrap';
import 'popper.js';
import AOS from 'aos';

import './custom/scripts';
import './component/navBar';
import './custom/jqScript';
import './custom/swiper';
import './component/multi-step-form';

// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import { Navigation, Pagination } from 'swiper';

// init Swiper:
const swiper = new Swiper('.swiper', {
	// configure Swiper to use modules
	modules: [Navigation, Pagination],
	// Optional parameters
	direction: 'horizontal',
	// 4 slides per view
	slidesPerView: 4,

	// mobile breakpoint
	breakpoints: {
		// when window width is <= 320px
		320: {
			slidesPerView: 1,
			spaceBetween: 10,
		},
		// when window width is <= 480px
		480: {
			slidesPerView: 1,
			spaceBetween: 20,
		},
		// when window width is <= 640px
		640: {
			slidesPerView: 2,
		},
		// when window width is <= 800px
		800: {
			slidesPerView: 3,
		},
		// when window width is <= 1200px
		1200: {
			slidesPerView: 4,
		},
	},

	spaceBetween: 30,
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

$(function() {
	//   $(".hero__img").attr("data-aos", "zoom-in");
	$('.experience-playbook__img').attr('data-aos', 'fade-down');

	AOS.init({
		easing: 'ease-out-back',
		duration: 1000,
		disable: function() {
			var maxWidth = 800;
			return window.innerWidth < maxWidth;
		},
	});

	window.AOS = AOS;
});

/* ======================
SMOOTH SCROLL
====================== */
$(document).ready(function() {
	// Add smooth scrolling to all links
	$('a').on('click', function(event) {
		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== '') {
			// Prevent default anchor click behavior
			event.preventDefault();

			// Store hash
			var hash = this.hash;

			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			$('html, body').animate(
				{
					scrollTop: $(hash).offset().top,
				},
				800,
				function() {
					// Add hash (#) to URL when done scrolling (default click behavior)
					window.location.hash = hash;
				}
			);
		} // End if
	});
});
